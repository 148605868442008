// import node_modules
import * as PropTypes from 'prop-types';
import * as React from 'react';
import dynamic from 'next/dynamic';
import { useImmer } from 'use-immer';
import { useImmerAtom } from 'jotai/immer';

// import modules
import { pauseVideos, iframeOptin } from '../../modules/privacy';
import { localeAtom } from '../../modules/jotai';

// import components
import StorylinerImage from '../atoms/StorylinerImage';

// import styles
import styles from './PreviewVideo.module.css';

// import components
const VideoModal = dynamic(() => import('../modals/VideoModal'));

// PreviewVideo component
const PreviewVideo: React.FunctionComponent<{
  videoPoster: string;
  videoTitle: string;
  videoUrl: string;
}> = ({ videoPoster, videoTitle, videoUrl }) => {
  // init hooks
  const [locale] = useImmerAtom(localeAtom);

  // init state
  const [modalState, setModalState] = useImmer<boolean>(false);

  // init func
  const onToggleModal = React.useCallback(() => {
    setModalState(!modalState);
  }, [modalState, setModalState]);

  // init iframe optin
  React.useEffect(() => {
    if (modalState) {
      iframeOptin({ autoplay: true, locale });
    } else {
      pauseVideos();
    }
  }, [locale, modalState]);

  return (
    <>
      <div className={styles['container']}>
        <div className={styles['innerContainer']}>
          <div className={styles['imageContainer']}>
            <div className={styles['imageInnerContainer']}>
              <button className={styles['imageButton']} onClick={onToggleModal} type="button">
                <span className={styles['imageButtonCanvas']} />
                <StorylinerImage
                  imageId={videoPoster}
                  height={900}
                  title={videoTitle}
                  width={1440}
                />
              </button>
              <div className={styles['snippetTop']} />
              <div className={styles['snippetBottomLeft']} />
              <div className={styles['snippetBottomRight']} />
            </div>
          </div>

          <div className={styles['backgroundCanvas']} />
        </div>
      </div>

      <VideoModal
        closeModal={onToggleModal}
        isActive={modalState}
        videoTitle={videoTitle}
        videoUrl={videoUrl}
      />
    </>
  );
};

PreviewVideo.propTypes = {
  videoPoster: PropTypes.string.isRequired,
  videoTitle: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired,
};

export default PreviewVideo;
