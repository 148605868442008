/* eslint-disable react/forbid-prop-types */

// import node_modules
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { Heading } from '@storylinerlabs/design';

// import components
import LinkComponent from '../atoms/LinkComponent';
import StorylinerImage from '../atoms/StorylinerImage';

// import styles
import styles from './PostCard.module.css';

// define component
const PostCard: React.FunctionComponent<{
  image: string;
  href: string;
  tags: string[];
  title: string;
}> = ({ image, href, tags, title }) => {
  // init render
  return (
    <LinkComponent href={href}>
      <div className={styles['card']}>
        <div className={styles['imageContainer']}>
          <StorylinerImage imageId={image} height={600} title={title} width={960} />
        </div>
        <div className={styles['nameContainer']}>
          <Heading customClasses={styles['heading']} tag="h3">
            {title}
          </Heading>
        </div>
        <div className={styles['tagsContainer']}>
          {tags?.map((tag) => (
            <div className={styles.tag} key={tag}>
              <Heading tag="label">{`#${tag.replace('-', '')}`}</Heading>
            </div>
          ))}
        </div>
      </div>
    </LinkComponent>
  );
};

PostCard.propTypes = {
  href: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  title: PropTypes.string.isRequired,
};

PostCard.displayName = 'PostCard';

export default PostCard;
