/* eslint-disable jsx-a11y/alt-text */

// import node_modules
import * as PropTypes from 'prop-types';
import * as React from 'react';

// import types
import { SectionColorEnum, SectionColor } from '../../types';

// import components
import Column from '../atoms/Column';
import Section from '../atoms/Section';
import StorylinerImage from '../atoms/StorylinerImage';

// define component
const LogosGrid: React.FunctionComponent<{
  bgColor: SectionColor;
  logos: { alt: string; src: string }[];
}> = ({ bgColor, logos }) => {
  // init vars
  const isBlack = React.useMemo(() => bgColor === SectionColorEnum.energyYellow, [bgColor]);

  // init render
  return (
    <Section bgColor={bgColor} mobileFlexDirection="row" zIndex={1}>
      {logos?.map((logo) => (
        <Column key={logo.src} cols={5}>
          <StorylinerImage
            height={360}
            title={logo.alt}
            isWhite={!isBlack}
            isBlack={isBlack}
            imageId={logo.src}
            width={720}
          />
        </Column>
      ))}
    </Section>
  );
};

LogosGrid.propTypes = {
  bgColor: PropTypes.oneOf(Object.values(SectionColorEnum)).isRequired,
  logos: PropTypes.arrayOf(
    PropTypes.shape({ alt: PropTypes.string.isRequired, src: PropTypes.string.isRequired })
      .isRequired,
  ).isRequired,
};

export default LogosGrid;
