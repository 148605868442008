/* eslint-disable react/jsx-props-no-spreading */

// import node_modules
import * as PropTypes from 'prop-types';
import * as React from 'react';
import NextImage from 'next/legacy/image';
import classNames from 'classnames';
import striptags from 'striptags';

// import styles
import styles from './StorylinerImage.module.css';

// Define component
export const StorylinerImage: React.FunctionComponent<{
  className?: string;
  hasShadow?: boolean;
  hasWhiteBg?: boolean;
  height?: number;
  imageId: string;
  isBlack?: boolean;
  isRounded?: boolean;
  isWhite?: boolean;
  title: string;
  width?: number;
}> = ({
  className,
  hasShadow,
  hasWhiteBg,
  height,
  imageId,
  isBlack,
  isRounded,
  isWhite,
  title,
  width,
}) => (
  <div
    className={classNames(
      styles['image'],
      isBlack && styles['is-black'],
      isWhite && styles['is-white'],
      isRounded && styles['is-rounded'],
      hasShadow && styles['has-shadow'],
      hasWhiteBg && styles['white-bg'],
    )}
  >
    <NextImage
      alt={striptags(title)}
      className={className}
      height={height}
      layout="responsive"
      loading="lazy"
      src={imageId}
      title={striptags(title)}
      width={width}
    />
  </div>
);

StorylinerImage.defaultProps = {
  className: undefined,
  hasShadow: undefined,
  hasWhiteBg: undefined,
  isBlack: false,
  isRounded: true,
  isWhite: false,
};

StorylinerImage.propTypes = {
  className: PropTypes.string,
  hasShadow: PropTypes.bool,
  hasWhiteBg: PropTypes.bool,
  height: PropTypes.number.isRequired,
  imageId: PropTypes.string.isRequired,
  isBlack: PropTypes.bool,
  isRounded: PropTypes.bool,
  isWhite: PropTypes.bool,
  title: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

export default StorylinerImage;
