// import node_modules
import * as PropTypes from 'prop-types';
import * as React from 'react';

// import types
import { TemplateGenreSlugEnum, TemplateGenre } from '@storylinerlabs/templates';
import { SectionColorEnum, SectionColor } from '../../types';

// import components
import Column from '../atoms/Column';
import Section from '../atoms/Section';
import GenreCard from '../cards/GenreCard';

// import styles
import styles from './GenresGrid.module.css';

// define component
const GenresGgrid: React.FunctionComponent<{
  bgColor: SectionColor;
  templateGenres: TemplateGenre[];
}> = ({ bgColor, templateGenres }) => (
  <Section bgColor={bgColor} isPaddingLess mobileFlexDirection="row" zIndex={1}>
    {templateGenres.map((genre) => (
      <Column className={styles['column']} key={genre.slug} isPaddingless>
        <GenreCard name={genre.name as string} image={genre.coverImageSquared as string} />
      </Column>
    ))}
    <Column className={styles['column']}>
      <div />
    </Column>
  </Section>
);

GenresGgrid.propTypes = {
  bgColor: PropTypes.oneOf(Object.values(SectionColorEnum)).isRequired,
  templateGenres: PropTypes.arrayOf(
    PropTypes.shape({
      coverImageSquared: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.oneOf(Object.values(TemplateGenreSlugEnum)).isRequired,
    }).isRequired,
  ).isRequired,
};

export default GenresGgrid;
