// import node_modules
import * as React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

// import styles
import styles from './GridSection.module.css';

// define types
export type GridSectionColor =
  | 'allports'
  | 'anakiwa'
  | 'desertStorm'
  | 'energyYellow'
  | 'java'
  | 'tuatara'
  | 'white';

export enum GridSectionColorEnum {
  allports = 'allports',
  anakiwa = 'anakiwa',
  desertStorm = 'desertStorm',
  energyYellow = 'energyYellow',
  java = 'java',
  tuatara = 'tuatara',
  white = 'white',
}

export type GridSectionAlignment = 'bottom' | 'middle' | 'top';

export enum GridSectionAlignmentEnum {
  bottom = 'bottom',
  middle = 'middle',
  top = 'top',
}

// define component
export const GridSection: React.FunctionComponent<{
  bgColor?: GridSectionColor;
  bgImage?: string;
  bgImageDark?: string;
  bgPosition?: string;
  children: React.ReactNode;
  columns?: number;
  sectionId?: string;
  verticalAlignment?: GridSectionAlignment;
  zIndex?: number;
}> = ({
  bgColor,
  bgImage,
  bgImageDark,
  bgPosition,
  children,
  columns,
  sectionId,
  verticalAlignment,
  zIndex,
}) => (
  <div
    className={classNames(
      styles['section'],
      bgColor && styles[`color-${bgColor}`],
      bgImage && styles[bgImage],
      bgImageDark && styles[`${bgImageDark}-dark`],
    )}
    id={sectionId}
    style={{ backgroundPosition: bgPosition, zIndex: zIndex || 0 }}
  >
    <div
      className={classNames(
        styles['container'],
        columns && styles[`col-${columns}`],
        verticalAlignment && styles[verticalAlignment],
      )}
    >
      {children}
    </div>
  </div>
);

GridSection.defaultProps = {
  bgColor: 'white',
  bgImage: undefined,
  bgImageDark: undefined,
  bgPosition: undefined,
  columns: 1,
  sectionId: '',
  verticalAlignment: 'middle',
  zIndex: 0,
};

GridSection.propTypes = {
  columns: PropTypes.number,
  bgColor: PropTypes.oneOf(Object.values(GridSectionColorEnum)),
  bgImage: PropTypes.string,
  bgImageDark: PropTypes.string,
  bgPosition: PropTypes.string,
  children: PropTypes.node.isRequired,
  sectionId: PropTypes.string,
  verticalAlignment: PropTypes.oneOf<GridSectionAlignmentEnum>(
    Object.values(GridSectionAlignmentEnum),
  ),
  zIndex: PropTypes.number,
};

export default GridSection;
