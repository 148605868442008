// import node_modules
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { useRouter } from 'next/router';

// import components
import Column from '../atoms/Column';
import PostCard from '../cards/PostCard';
import { GridSection } from '../atoms/GridSection';

// define types
export type PostGridColor =
  | 'allports'
  | 'anakiwa'
  | 'desertStorm'
  | 'energyYellow'
  | 'java'
  | 'tuatara'
  | 'white';

export enum PostGridColorEnum {
  allports = 'allports',
  anakiwa = 'anakiwa',
  desertStorm = 'desertStorm',
  energyYellow = 'energyYellow',
  java = 'java',
  tuatara = 'tuatara',
  white = 'white',
}

// define components
const PostsGrid: React.FunctionComponent<{
  bgColor: PostGridColor;
  blogPosts: {
    image: string;
    slug: string;
    tags: string[];
    title: string;
  }[];
}> = ({ bgColor, blogPosts }) => {
  // init hooks
  const { asPath } = useRouter();

  // init render
  return (
    <GridSection bgColor={bgColor} columns={3} verticalAlignment="top">
      {blogPosts?.map((post) => (
        <Column key={post?.title}>
          <PostCard
            href={asPath?.includes('/de/') ? `/de/blog/${post?.slug}` : `/blog/${post?.slug}`}
            image={post?.image}
            tags={post?.tags}
            title={post?.title}
          />
        </Column>
      ))}
    </GridSection>
  );
};

PostsGrid.propTypes = {
  bgColor: PropTypes.oneOf(Object.values(PostGridColorEnum)).isRequired,
  blogPosts: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      tags: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default PostsGrid;
