/* eslint-disable jsx-a11y/alt-text */

// import node_modules
import * as PropTypes from 'prop-types';
import * as React from 'react';
import dynamic from 'next/dynamic';
import { Heading, LinkButton, Spacer, Text } from '@storylinerlabs/design';
import { useTranslation } from 'react-i18next';

// import types
import { TemplateGenreSlugEnum, TemplateGenre } from '@storylinerlabs/templates';
import { QuoteColor, SectionColor } from '../../types';

// import components
import Column from '../atoms/Column';
import GenresGrid from '../grids/GenresGrid';
import LogosGrid from '../grids/LogosGrid';
import MainLayout from '../layouts/MainLayout';
import PostsGrid from '../grids/PostsGrid';
import PreviewVideo from '../elements/PreviewVideo';
import SEO from '../meta/SEO';
import Section from '../atoms/Section';

// import styles
import styles from './HomeTemplate.module.css';

// import dynamically
const EarlyAccessSection = dynamic(() => import('../elements/EarlyAccessSection'));
const LinkComponent = dynamic(() => import('../atoms/LinkComponent'));
const Quote = dynamic(() => import('../atoms/Quote'));
const StorylinerIcon = dynamic(() => import('../atoms/StorylinerIcon'));
const StorylinerImage = dynamic(() => import('../atoms/StorylinerImage'));

// define page
const HomeTemplate: React.FunctionComponent<{
  blogPosts: { image: string; slug: string; tags: string[]; title: string }[];
  hrefLang: string;
  templateGenres: TemplateGenre[];
}> = ({ blogPosts, hrefLang, templateGenres }) => {
  // init hooks
  const { t } = useTranslation('home');

  // init render
  return (
    <>
      <SEO
        description={t('seo.description')}
        hrefLang={hrefLang}
        image={t('seo.image')}
        title={t('seo.title')}
      />

      <MainLayout>
        {/* Hero  */}
        <Section>
          {/* Navbar Spacer */}
          <Spacer height="64px" />
          <Spacer mobileHeight="25px" tabletHeight="50px" height="75px" />
        </Section>
        <Section>
          <Column cols="ml">
            <Heading alignment="center" tag="h1" isHyphenated={false}>
              {t('hero.title')}
            </Heading>

            <Text alignment="center" size="large">
              {t('hero.intro')}
            </Text>
          </Column>
        </Section>

        {/* Product Preview  */}
        <PreviewVideo
          videoPoster={t('productPreview.videoPoster')}
          videoTitle={t('hero.title')}
          videoUrl={t('productPreview.videoUrl')}
        />

        {/* Press  */}
        <Section bgColor="tuatara">
          <Column>
            <Heading alignment="center" tag="label">
              {t('press.title')}
            </Heading>
          </Column>
        </Section>
        <LogosGrid bgColor="tuatara" logos={t('press.logos', { returnObjects: true })} />
        <Section bgImage={t('press.dividerBottom')} bgPosition="center bottom" zIndex={0}>
          <Spacer mobileHeight="100px" tabletHeight="125px" height="175px" />
        </Section>

        {/* Benefits */}
        <Section bgColor="white">
          <Spacer height="100px" />
        </Section>
        <Section bgColor="white">
          <Column>
            <Heading alignment="center" tag="h2">
              {t('benefits.title')}
            </Heading>
            <Spacer mobileHeight="25px" height="25px" />
          </Column>
        </Section>
        {(
          t('benefits.items', { returnObjects: true }) as {
            description: string;
            headline: string;
            image: string;
          }[]
        ).map((benefit, index) => {
          if (index % 2 !== 0) {
            return (
              <React.Fragment key={benefit.headline}>
                <Section mobileFlexDirection="column-reverse" tabletFlexDirection="row">
                  <Column cols={2}>
                    <Heading alignment="left" tag="h3">
                      {benefit.headline}
                    </Heading>
                    <Text alignment="left">{benefit.description}</Text>
                  </Column>
                  <Column cols={2}>
                    <StorylinerImage
                      imageId={benefit.image}
                      height={560}
                      title={benefit.headline}
                      width={960}
                    />
                  </Column>
                </Section>
                <Section bgColor="white">
                  <Spacer height="25px" />
                </Section>
              </React.Fragment>
            );
          }
          return (
            <React.Fragment key={benefit.headline}>
              <Section mobileFlexDirection="column" tabletFlexDirection="row">
                <Column cols={2}>
                  <StorylinerImage
                    imageId={benefit.image}
                    height={560}
                    title={benefit.headline}
                    width={960}
                  />
                </Column>
                <Column cols={2}>
                  <Heading alignment="left" tag="h3">
                    {benefit.headline}
                  </Heading>
                  <Text alignment="left">{benefit.description}</Text>
                </Column>
              </Section>
              <Section bgColor="white">
                <Spacer height="25px" />
              </Section>
            </React.Fragment>
          );
        })}
        <Section>
          <Column>
            <div className="button-container">
              <LinkComponent href={t('benefits.callToAction.href')}>
                <LinkButton
                  color="naked"
                  size="large"
                  text={t('benefits.callToAction.label')}
                  iconRight="ri-arrow-right-line"
                  textIsPrimary
                />
              </LinkComponent>
            </div>
          </Column>
        </Section>
        <Section>
          <Spacer height="75px" />
        </Section>

        {/* Testimonial  */}
        <Section bgColor="allports">
          <Spacer height="50px" />
        </Section>
        <Quote
          bgColor={t('testimonial.bgColor') as SectionColor}
          color={t('testimonial.color') as QuoteColor}
          colorDark={t('testimonial.colorDark') as QuoteColor}
          image={t('testimonial.image')}
          jobTitle={t('testimonial.jobTitle')}
          name={t('testimonial.name')}
          quote={t('testimonial.quote')}
          snippet={t('testimonial.snippet')}
          snippetDark={t('testimonial.snippetDark')}
        />
        <Section bgColor="allports">
          <Spacer height="50px" />
        </Section>

        {/* Genres  */}
        <Section bgColor="desertStorm">
          <Spacer height="50px" />
        </Section>
        <Section bgColor="desertStorm">
          <Column className={styles['genre-grid-intro']}>
            <Heading alignment="left" tag="h3">
              {t('genres.title')}
            </Heading>

            <Text alignment="left">{t('genres.intro')}</Text>

            <div className="button-container button-left">
              <LinkComponent href={t('genres.callToAction.href')}>
                <LinkButton
                  color="ultraLight"
                  text={t('genres.callToAction.label')}
                  iconRight="ri-arrow-right-line"
                  textIsPrimary
                />
              </LinkComponent>
            </div>
          </Column>
          <Column className={styles['genre-grid-container']} isPaddingless>
            <GenresGrid bgColor="desertStorm" templateGenres={templateGenres} />
          </Column>
        </Section>
        <Section
          bgImage={t('genres.dividerBottom')}
          bgImageDark={t('genres.dividerBottomDark')}
          bgPosition="center bottom"
        >
          <Spacer mobileHeight="100px" tabletHeight="125px" height="150px" />
        </Section>

        {/* Audiences  */}
        <Section>
          <Spacer height="75px" />
        </Section>
        <Section>
          <Column>
            <Heading alignment="center" tag="h3">
              {t('audiences.title')}
            </Heading>
            <Spacer height="24px" />
          </Column>
        </Section>
        <Section verticalAlignment="top">
          {(
            t('audiences.items', { returnObjects: true }) as {
              description: string;
              icon: string;
              name: string;
            }[]
          ).map((audience) => (
            <Column cols={3} key={audience.name}>
              <StorylinerIcon icon={audience.icon} />
              <Spacer height="8px" />
              <Heading alignment="left" tag="h4">
                {audience.name}
              </Heading>
              <Text alignment="left">{audience.description}</Text>
            </Column>
          ))}
          <Column cols={3}>
            <div />
          </Column>
        </Section>
        <Section>
          <Column>
            <div className="button-container">
              <LinkComponent href={t('audiences.callToAction.href')}>
                <LinkButton
                  color="naked"
                  size="large"
                  text={t('audiences.callToAction.label')}
                  iconRight="ri-arrow-right-line"
                  textIsPrimary
                />
              </LinkComponent>
            </div>
          </Column>
        </Section>
        <Section>
          <Spacer height="75px" />
        </Section>

        {/* Blog  */}
        <Section bgColor="desertStorm">
          <Spacer height="75px" />
        </Section>
        <Section bgColor="desertStorm">
          <Column cols="ml">
            <Heading alignment="center" isHyphenated={false} tag="h2">
              {t('blog.title')}
            </Heading>
          </Column>
        </Section>
        <PostsGrid bgColor="desertStorm" blogPosts={blogPosts} />
        <Section bgColor="desertStorm">
          <Spacer height="75px" />
        </Section>

        {/* Call to action  */}
        <Section>
          <Spacer height="100px" />
        </Section>
        <EarlyAccessSection />
        <Section>
          <Spacer height="100px" />
        </Section>
      </MainLayout>
    </>
  );
};

HomeTemplate.propTypes = {
  blogPosts: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      tags: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  hrefLang: PropTypes.string.isRequired,
  templateGenres: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.oneOf(Object.values(TemplateGenreSlugEnum)).isRequired,
      coverImageSquared: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default HomeTemplate;
