/* eslint-disable react/forbid-prop-types */

// import node_modules
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { Heading } from '@storylinerlabs/design';

// import components
import StorylinerImage from '../atoms/StorylinerImage';

// import styles
import styles from './GenreCard.module.css';

// define component
const GenreCard: React.FunctionComponent<{
  image: string;
  name: string;
}> = ({ name, image }) => (
  <div className={styles['container']}>
    <div className={styles['card']}>
      <div className={styles['cardImageContainer']}>
        <StorylinerImage
          className={styles['cardImage']}
          height={540}
          imageId={image}
          title={name}
          width={540}
        />
      </div>
      <div className={styles['cardNameContainer']}>
        <Heading tag="h4">{name}</Heading>
      </div>
    </div>
  </div>
);

GenreCard.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

GenreCard.displayName = 'GenreCard';

export default GenreCard;
